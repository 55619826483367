import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Movements = () => {
  
  let navigate=useNavigate()
  useEffect(()=>{
    if(localStorage.getItem("studenttoken")){
        fetch("http://localhost:3000/api/studentid",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "token":localStorage.getItem("studenttoken")
            },body:JSON.stringify({method:6})
        }).then((ele)=>ele.json()).then((res)=>{
            if(!res.flag1){
                navigate("/profile")
            }
            else{
                navigate("/needassesment")
            }
          
        })
    }
        },[])
  useEffect(() => {
    let x=document.getElementById("magic-container")
    let y=document.getElementById("downmagic")
    x.addEventListener("scroll",()=>{
    if(x.scrollTop!=0){
    y.style.backgroundColor="rgb(186, 136, 160)"
    }
    else{
      y.style.backgroundColor="transparent"
    }
    })
  }, [])
  return (
<section className="sectionArt">
      <div className="sectionArtContainer">
        <div className="socials">
          <button className="linkedin icon">
            <i className="fa-brands fa-linkedin-in"></i>
          </button>
          <button className="instagram icon">
            <i className="fa-brands fa-instagram"></i>
          </button>
          <button className="twitter icon">
            <i className="fa-brands fa-twitter"></i>
          </button>
        </div>
        <div className="artContainer" id="magic-container">
          <div className="part1" id="downmagic">
            <button className="backBtn">
              <i className="fa-solid fa-angle-left"></i> Back
            </button>
            <h2 className="artHeading">Art</h2>
          </div>
          <div className="artProgress">
            <div className="part2">
              <h3 className="part2Heading">Qualitative Progress</h3>
              <i className="fa-solid fa-quote-left"></i>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Maiores, aliquid accusamus! Eveniet reiciendis ratione totam?
                Molestias exercitationem similique laboriosam et odit error
                odio, ratione sint quos praesentium tempore id minima?
              </p>
              <i className="fa-solid fa-quote-right"></i>
            </div>
            <div className="part3">
              <h3 className="part3Heading">Qualitative Progress</h3>
              <div className="checks">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
                  assumenda.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
                  assumenda.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
                  assumenda.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
                  assumenda.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi,
                  assumenda.
                </p>
              </div>
            </div>
            <div className="part4">
              <h3 className="part4Heading">Participant Rubric</h3>
              <table className="participantTable">
                <tr className="heading tableBackground">
                  <th>Column</th>
                  <th>Column</th>
                  <th>Column</th>
                  <th>Column</th>
                </tr>
                <tr>
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                </tr>
                <tr className="tableBackground">
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                </tr>
                <tr>
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                </tr>
                <tr className="tableBackground">
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                  <td>Row</td>
                </tr>
              </table>
            </div>
            <div className="part5">
              <h3 className="part5Heading">Progress Sliders</h3>
              <div className="progressBars">
                <div className="bar1">
                  <p>Column</p>
                  <div className="progress">
                    <div
                      className="progress-bar w-25"
                      role="progressbar"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p>00%</p>
                </div>
                <div className="bar2">
                  <p>Column</p>
                  <div className="progress">
                    <div
                      className="progress-bar w-50"
                      role="progressbar"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p>00%</p>
                </div>
                <div className="bar3">
                  <p>Column</p>
                  <div className="progress">
                    <div
                      className="progress-bar w-75"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p>00%</p>
                </div>
                <div className="bar4">
                  <p>Column</p>
                  <div className="progress">
                    <div
                      className="progress-bar w-50"
                      role="progressbar"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p>00%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Movements