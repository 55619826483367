
import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

import Context from './context/Mycontext'

const Home = () => {
    let navigate=useNavigate()
    useEffect(()=>{
      if(localStorage.getItem("studenttoken")){
          fetch("http://localhost:3000/api/studentid",{
              method:"POST",
              headers:{
                  "Content-Type":"application/json",
                  "token":localStorage.getItem("studenttoken")
              },body:JSON.stringify({method:6})
          }).then((ele)=>ele.json()).then((res)=>{
              if(!res.flag1){
                  navigate("/profile")
              }
              else{
                  navigate("/needassesment")
              }
            
          })
      }
          },[])
let context=useContext(Context)
let {Progress,setProgress}=context
  return (
   <>

   <section className='w-100'>
        <div className="home-page">
            <div className="socials">
                <button className="linkedin icon">
                    <i className="fa-brands fa-linkedin-in"></i>
                </button>
                <button className="instagram icon">
                    <i className="fa-brands fa-instagram"></i>
                </button>
                <button className="twitter icon">
                    <i className="fa-brands fa-twitter"></i>
                </button>
            </div>

            <div className="home-container">
                <h3>Goodmorning,Ravi</h3>
                <div className="therapy-section">
                    <div className="therapy-title">
                        <h4>Immersive Therapy</h4>
                    </div>
                    <div className="therapy-item">
                        <div className="art icon">
                            <Link to="arts" className='env-icon'>
                            <i className="fa-solid fa-palette"></i>
                            <p>Art</p>
                            </Link>
                        </div>
                        <div className="music icon">
                            <Link to="/music" className='env-icon'>
                            <i className="fa-solid fa-music"></i>
                            <p>Music</p>
                            </Link>
                        </div>
                        <div className="movement icon">
                            <Link to="/movements" className='env-icon'>
                            <i className="fa-solid fa-arrow-trend-up"></i>
                            <p>Movement</p>
                            </Link>
                            
                        </div>
                        <div className="nature icon">
<Link to="nature" className='env-icon'>
                            <i className="fa-brands fa-pagelines"></i>
                            <p>Nature</p>
</Link>
                        </div>
                    </div>
                </div>


                <div className="home-downpart">
                    <div className="home-downleftsection">
                        <div className="calender">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet delectus corrupti, quia in
                            placeat explicabo alias minima officiis dolor libero, quaerat quisquam blanditiis
                            dignissimos beatae. Earum iste laudantium velit impedit!

                        </div>
                        <div className="publisher-desk">
                            <p>Publisher's Desk</p>
                            <div className="publisher-item">
                                <img src="images/Ellipse 3.png" alt=""/><span>Amit Das</span>
                                <p>Tuition eats into domenstic-bugests in Chennal's slums</p>
                            </div>
                            <div className="publisher-item">
                                <img src="images/Ellipse 3.png" alt=""/><span>Amit Das</span>
                                <p>State School Science Fest to Kick off in Kochi on Thursday</p>
                            </div>
                            <a href="#">See all list</a>
                        </div>
                    </div>

                    <div className="home-downrightsection">
                        <div className="performance-box">
                            <h4>Performance index</h4>
                            <div className="image-section">
                                <div className="image-group">
                                    <img className="redcircle" src="images/red.png" alt=""/>
                                    <img className="purplecircle" src="images/purple.png" alt=""/>
                                    <img className="skybluecircle" src="images/skyblue.png" alt=""/>
                                    <img className="greencircle" src="images/green.png" alt=""/>
                                </div>
                                <img className="Vector" src="images/Vector(2).png" alt="" width="200px" style={{zIndex: "1"}}/>
                            </div>
                        </div>
                        <div className="testimonials">
                            <h3>Testimonials</h3>
                            <p>say something</p>
                            <input type="text" placeholder="New message"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   </>
  )
}

export default Home