import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Needassesment = () => {
let navigate=useNavigate()
  useEffect(()=>{
    if(localStorage.getItem("studenttoken")){
        fetch("http://localhost:3000/api/studentid",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "token":localStorage.getItem("studenttoken")
            },body:JSON.stringify({method:6})
        }).then((ele)=>ele.json()).then((res)=>{
            if(!res.flag1){
                navigate("/profile")
            }
            else{
                navigate("/needassesment")
            }
          
        })
    }
        },[])

    useEffect(()=>{
        
          document.getElementById("defaultOpen").click();
    },[])

    useEffect(()=>{
        var acc = document.getElementsByClassName("quest");
        var i;
        
        for (i = 0; i < acc.length; i++) {
          acc[i].onclick = function () {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
            }
          };
        }
    },[])

    const openCity=(cityName, elmnt)=> {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink");
  
        document.getElementById(cityName).style.display = "block";
      }

  return (
  <>
  
  <section className="section1">
      <div className="needAssessmentContainer">
        <div className="socials">
          <button className="linkedin icon">
            <i className="fa-brands fa-linkedin-in"></i>
          </button>
          <button className="instagram icon">
            <i className="fa-brands fa-instagram"></i>
          </button>
          <button className="twitter icon">
            <i className="fa-brands fa-twitter"></i>
          </button>
        </div>
        <div className="assessmentContainer">
          <h2 className="assessmentHeading">Need Assessment</h2>
          <div className="famTeaBtn" id="famTeamBtns">
            <button
              className="family tablink famTeabtn"
              onClick={()=>openCity('Family', this)}
              id="defaultOpen"
            >
              Form 1
            </button>
            <button
              className="teacher tablink famTeabtn"
              onClick={()=>openCity('Teacher', this)}
            >
              Form 2
            </button>
          </div>
          <hr className="hr1" />
          <div className="questions">
            <p className="familyQuestions">Diagnostic Questions</p>
            <div className="question tabcontent" id="Family">
              <div className="q1">
                <button className="quest">
                  What kind of music do you like to listen to?<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q2">
                <button className="quest">
                  Do you enjoy singing or playing any musical instruments? If
                  yes, which ones?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q3">
                <button className="quest">How does music make you feel?</button>
                <div className="custom-select">
                  <select>
                    <option value="Happy">Happy</option>
                    <option value="Calm">Calm</option>
                    <option value="Excited">Excited</option>
                    <option value="Sad">Sad</option>
                    <option value="Irritated">Irritated</option>
                    <option value="Angry">Angry</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q4">
                <button className="quest">
                  Have you ever used music to express your emotions? If yes,
                  please share an example.<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q5">
                <button className="quest">
                  Do you study/work along with music played?
                </button>
                <div className="radio">
                  <div className="yesRadio">
                    <input
                      type="radio"
                      checked="checked"
                      name="study"
                      value="yes"
                    />
                    <label for="">Yes</label>
                  </div>
                  <div className="yesRadio">
                    <input type="radio" name="study" value="no" />
                    <label for="">No</label>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q6">
                <button className="quest">
                  Do you participate in daily musical activities? (Puja,
                  Parties, etc.)<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q7">
                <button className="quest">
                  Where would you rate the value of music in your life (Out of
                  10)?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q8">
                <button className="quest">
                  What kind of songs/music/tunes do you prefer listening to
                  mostly?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q9">
                <button className="quest">
                  What level of volume do you prefer for music listening (Out of
                  10)?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q10">
                <button className="quest">
                  What are some of the activities you do along with music
                  played?
                </button>
                <div className="custom-select">
                  <select>
                    <option value="Drive">Drive</option>
                    <option value="Cook">Cook</option>
                    <option value="Gardening">Gardening</option>
                    <option value="Study">Study</option>
                    <option value="Bath">Bath</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q11">
                <button className="quest">
                  Have you tried listening to your favourite track when feeling
                  low? If yes, share your experience.<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q12">
                <button className="quest">
                  Do you like drawing, painting, or creating art? If yes, what
                  do you enjoy creating the most?<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q13">
                <button className="quest">
                  What art mediums have you used (crayons, water colours,
                  sketching pencils etc)? Did you enjoy any one medium over the
                  others?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q14">
                <button className="quest">
                  How do you feel when you are making art? (e.g., relaxed,
                  focused, inspired, Other.. Give example of the above.)<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q15">
                <button className="quest">
                  Have you ever used art to express your thoughts or feelings?
                  If yes, please share an example.<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q16">
                <button className="quest">
                  Do you prefer using colours in your art work? What colours do
                  you like the most?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q17">
                <button className="quest">
                  Do you enjoy spending time outdoors in nature? If yes, what
                  activities do you like doing outside?
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q18">
                <button className="quest">
                  How do you feel when you are in nature? (e.g., peaceful,
                  connected, energised, Give Example)<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q19">
                <button className="quest">
                  Is there a particular place in nature that you find special or
                  calming? If yes, where is it?<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q20">
                <button className="quest">
                  Have you ever noticed any special plants or animals in nature
                  that you like? If yes, please describe them<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q21">
                <button className="quest">
                  What patterns did you usually observe on them? Specify<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q22">
                <button className="quest">
                  What elements in nature are you attracted to? (e.g.
                  asymmetrical trees, mountain , beaches, vivid flowers etc.)
                  and Why?
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q23">
                <button className="quest">
                  What kind of colours do you get attracted to in nature?<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q24">
                <button className="quest">
                  Where do you observe and perceive nature in everyday life?
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q25">
                <button className="quest">
                  Do you enjoy physical activities like running, dancing,yoga or
                  sports? If yes, which ones, specify?<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q26">
                <button className="quest">How often do you do them?</button>
                <div className="custom-select">
                  <select>
                    <option value="Everyday a week">Everyday a week</option>
                    <option value="Twice a week">Twice a week</option>
                    <option value="Thrice a week">Thrice a week</option>
                    <option value="Once a week">Once a week</option>
                    <option value="Rarely">Rarely</option>
                  </select>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q27">
                <button className="quest">
                  How do you feel when you are moving your body?
                </button>
                <div className="custom-select">
                  <select>
                    <option value="Strong">Strong</option>
                    <option value="Free">Free</option>
                    <option value="Joyful">Joyful</option>
                    <option value="In Pain">In Pain</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q28">
                <button className="quest">
                  Have you ever used movement to express your emotions? If yes,
                  please share an example.<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q29">
                <button className="quest">
                  Is there anything specific you would like to do or learn
                  through movement-based activities? Specify<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q30">
                <button className="quest">
                  Among music, art, nature, and movement, which one do you enjoy
                  the most, and why?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q31">
                <button className="quest">
                  Is there any specific activity from these domains that you
                  feel helps you the most when you are feeling upset or
                  stressed?<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q32">
                <button className="quest">
                  Are there any other activities or interests that you would
                  like to explore during sessions?
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q33">
                <button className="quest">
                  Is there anything else you would like to share about yourself
                  or your experiences related to therapy in case you have
                  undertaken one before.yes/No specify<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q34">
                <button className="quest">
                  Do you find it easy to say no to people? If yes, where is it
                  easy?Mention.<i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q35">
                <button className="quest">
                  What are the most common statements with which you describe
                  yourself? Mention in detail.<i
                    className="fa-regular fa-pen-to-square"
                  ></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q36">
                <button className="quest">
                  Is there anything you would like to add which you would like
                  us to know further <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <div className="panel">
                  <textarea
                    placeholder="Input text here"
                    className="input2"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="question tabcontent" id="Teacher">
              <div className="q1">
                <h3>1. General Behavior</h3>
                <div className="q1A">
                  <button className="quest">
                    a) Describe the child's overall temperament. Is the child
                    generally calm, energetic, shy, assertive, etc.?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q1B">
                  <button className="quest">
                    b) How does the child respond to social interactions with
                    peers and adults?
                  </button>
                  <div className="radio">
                    <div className="introvertRadio">
                      <input
                        type="radio"
                        checked="checked"
                        name="introvertExtrovert"
                        value="Introvert"
                      />
                      <label for="">Introvert</label>
                    </div>
                    <div className="extrovertRadio">
                      <input
                        type="radio"
                        name="introvertExtrovert"
                        value="Extrovert"
                      />
                      <label for="">Extrovert</label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q2">
                <h3>2. Sleep Patterns:</h3>
                <div className="q2A">
                  <button className="quest">
                    a) Describe the child's sleep habits. Does the child sleep
                    deeply or have difficulty falling asleep?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q2B">
                  <button className="quest">
                    b) How many hours of sleep does the child typically get at
                    night?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q2C">
                  <button className="quest">
                    c) Are there any abnormal sleep pattern observed
                  </button>
                  <div className="custom-select">
                    <select>
                      <option value="Nightmares">Nightmares</option>
                      <option value="Bedwetting">Bedwetting</option>
                      <option value="Teeth grinding">Teeth grinding</option>
                      <option value="Sleep talking">Sleep talking</option>
                      <option value="Sleep walking">Sleep walking</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q3">
                <h3>3. Eating Habits:</h3>
                <div className="q3A">
                  <button className="quest">
                    a) Observe the child's eating preferences and habits. Does
                    the child have a strong or weak appetite?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q3B">
                  <button className="quest">
                    b) Is there a preference for certain types of foods
                  </button>
                  <div className="custom-select">
                    <select>
                      <option value="Sweet">Sweet</option>
                      <option value="Sour">Sour</option>
                      <option value="Savoury">Savoury</option>
                      <option value="Dairy">Dairy</option>
                      <option value="Vegan">Vegan</option>
                      <option value="Lean Meat">Lean Meat</option>
                      <option value="Red Meat">Red Meat</option>
                      <option value="Fruits">Fruits</option>
                      <option value="Bitter">Bitter</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="q3C">
                  <button className="quest">
                    c) what time prefers the heaviest meal
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q4">
                <h3>4. Response to Environment:</h3>
                <div className="q4A">
                  <button className="quest">
                    a) How does the child react to changes in their environment,
                    such as new places or routines?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Comfort to extreme discomfort"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q4B">
                  <button className="quest">
                    b) Does the child seem more sensitive or resilient to
                    sensory stimuli (e.g., noise, light)?specify
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q5">
                <h3>5. Play and Activity Preferences:</h3>
                <div className="q5A">
                  <button className="quest">
                    a) Describe the child's preferred play activities and
                    interests.specify
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="Input text here"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q5B">
                  <button className="quest">
                    b) Does the child enjoy active physical play or more quiet,
                    imaginative play?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="active physical - quiet imaginative"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q6">
                <h3>6. Emotional Expressions:</h3>
                <div className="q6A">
                  <button className="quest">
                    a) Observe the child's emotional responses in different
                    situations. Does the child easily express joy, anger,
                    sadness, etc.?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="easy emotional expression to difficult"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q6B">
                  <button className="quest">
                    b) How does the child cope with emotional challenges or
                    changes in their emotional state?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="coping strength low to high"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
              <div className="q7">
                <h3>7. Illness and Health:</h3>
                <div className="q7A">
                  <button className="quest">
                    a) Note the child's overall health status. Does the child
                    tend to get sick frequently or have robust health?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="health to illness"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
                <div className="q7B">
                  <button className="quest">
                    b) How does the child recover from illnesses or injuries?
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <div className="panel">
                    <textarea
                      placeholder="recovering illness, quick to slow"
                      className="input2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="hr2" />
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </>
  )
}

export default Needassesment