import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
const Profile = () => {
    

    const [credentials, setcredentials] = useState({imgfile:"",age:"",gender:"",dob:"",fathername:"",mothername:"",location:""})
   
    let navigate=useNavigate()
  useEffect(()=>{
    if(localStorage.getItem("studenttoken")){
        fetch("http://localhost:3000/api/studentid",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "token":localStorage.getItem("studenttoken")
            },body:JSON.stringify({method:6})
        }).then((ele)=>ele.json()).then((res)=>{
            if(!res.flag1){
                navigate("/profile")
            }
            else{
                navigate("/needassesment")
            }
          
        })
    }
        },[])


        const handlechange=(e)=>{
            const file = e.target.files[0];
            const reader = new FileReader();
        
            reader.onloadend = () => {
           setcredentials({...credentials,imagefile:reader.result})
            };
        
            if (file) {
              reader.readAsDataURL(file);
            }
        }


        const handlechange2=(e)=>{
            setcredentials({...credentials,[e.target.name]:e.target.value})
            console.log(credentials)
        }
  return (
   <>
   
   <section className='w-100'>
        <div className="form-page">
            <div className="socials">
                <button className="linkedin icon">
                    <i className="fa-brands fa-linkedin-in"></i>
                </button>
                <button className="instagram icon">
                    <i className="fa-brands fa-instagram"></i>
                </button>
                <button className="twitter icon">
                    <i className="fa-brands fa-twitter"></i>
                </button>
            </div>

            <div className="form-container">
                <img src="formimage.svg" alt=""/>
                <div className="form-main-section">
                    <h1>Registration Form</h1>
                    <form className="form-details" action="">

                        <label for="age" className="age-input"><i className="fa-solid fa-map-pin"></i> Your Age </label>
                        <input type="text" id="age" onChange={handlechange2} name="age" placeholder="12years"/>

                         <div className="gender-container">
                            <h3>Gender</h3>
                            <div className="male-box">
                                <input type="radio" onChange={handlechange2} id="male" name="gender" value="Male"/>
                                <label for="male"><i className="fa-solid fa-child"></i> Male</label>
                            </div>
                            <div className="female-box">
                                <input type="radio" onChange={handlechange2}id="female" name="gender" value="Female"/>
                                <label for="female"><i className="fa-solid fa-child-dress"></i> Female</label>
                            </div>
                         </div>

                        <label for="dob" className="dob-input"><i className="fa-solid fa-calendar-days"></i> Date of Birth </label>
                        <div className="parent-container">
                            <h3>Parent/Guardian Name</h3>
                            <label for="father-name"><i className="fa-solid fa-user-tie"></i> Father Name</label>
                            <input type="text" id="father-name" onChange={handlechange2} name="fathername" placeholder="Mr."/>

                            <label for="mother-name"><i className="fa-solid fa-person-breastfeeding"></i> Mother Name</label>
                            <input type="text" id="mother-name" onChange={handlechange2} name="mothername" placeholder="Mrs."/>
                        </div>

                        <label for="location"><i className="fa-solid fa-location-dot"></i> Participate's Location</label>
                        <textarea name="location" onChange={handlechange2} id="location" cols="30" rows="3" placeholder="Full Address"></textarea>
                        
                        <label for="img-file" className="p-img-input"><i className="fa-solid fa-image"></i> Participate's Image</label>
                        <input type="file" accept='image/jpeg , image/png ,image/jpg, ' onChange={handlechange} id="img-file" name="img-file"/>
                        <button>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </section> 
   
   </>
  )
}

export default Profile