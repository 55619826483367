
import './App.css';
import { BrowserRouter,Route,Routes, useNavigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Profile from './Components/Profile';
import Needassesment from './Components/Needassesment';
import Arts from './Components/Arts';
import Movements from './Components/Movements';
import Nature from './Components/Nature';

import LoadingBar from 'react-top-loading-bar'
import Contextstate from './Components/context/Contextstate';
import Details from './Components/Details';
import Music from './Components/Music';
import Login from './Components/Login';
import { useEffect } from 'react';

function App() {


  return (

<Contextstate>

  <div className='d-flex'>
  <BrowserRouter>
  <Navbar/>
  <Routes>
    
      
<Route exact path='/' element={<Home/>}/>
<Route exact path='/profile' element={<Profile/>}/>
<Route exact path='/needassesment' element={<Needassesment/>}></Route>
<Route exact path='/arts' element={<Arts/>}></Route>
<Route exact path="/movements" element={<Movements/>}></Route>
<Route exact path='/music' element={<Music/>}></Route>
<Route exact path='/nature' element={<Nature/>}></Route>
    <Route exact path='/aboutus' element={<Details/>}></Route>
    <Route exact path='/login' element={<Login/>}></Route>
  </Routes>
  </BrowserRouter>
  </div>
 
</Contextstate>
  );
}

export default App;
