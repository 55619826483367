import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const Login = () => {
let navigate=useNavigate()
    const [credentials, setcredentials] = useState({studentId:"",password:""})
    const handlechange=(e)=>{
        setcredentials({...credentials,[e.target.name]:e.target.value})
    }


    useEffect(()=>{
if(localStorage.getItem("studenttoken")){
    navigate("/")
}


    },[])

    const handlesubmit=(e)=>{
        e.preventDefault()
        fetch("http://localhost:3000/api/studentid",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },body:JSON.stringify({studentId:credentials.studentId,password:credentials.password,method:5})
        }).then((res)=>res.json()).then((ele)=>{

            if(ele.success){
                
                localStorage.setItem("studenttoken",ele.token)
                localStorage.setItem("studentname",ele.studentname)
                toast.success(ele.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition:Bounce
                    });
       
                    setTimeout(()=>{
                            navigate("/")
                    },1000)
            }

            else{
                toast.error(ele.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition:Bounce
                    });
    
            }
        })
    }



  return (
   <>
   <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
transition={"Bounce"}
/>
   <section className='w-100'>
        <div className="form-page">
            <div className="socials">
                <button className="linkedin icon">
                    <i className="fa-brands fa-linkedin-in"></i>
                </button>
                <button className="instagram icon">
                    <i className="fa-brands fa-instagram"></i>
                </button>
                <button className="twitter icon">
                    <i className="fa-brands fa-twitter"></i>
                </button>
            </div>

            <div className="form-container">
                <img src="formimage.svg" alt=""/>
                <div className="form-main-section">
                    <h1>Login Form</h1>
                    <form className="form-details" onSubmit={handlesubmit}>
                    
                        <div className="parent-container">
        
                            <label htmlFor="studentId"><i className="fa-solid fa-user-tie"></i>Student ID</label>
                            <input type="text" id="studentId" onChange={handlechange} name="studentId" placeholder="Your Student Id"/>

                            <label htmlFor="password"><i className="fa-solid fa-person-breastfeeding"></i>Student Password</label>
                            <input type="password" id="password" onChange={handlechange} name="password" placeholder="Your Secret Password"/>
                        </div>


                        <button type='submit'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </section> 
   
   </>
  )
}

export default Login