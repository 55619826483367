import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
    let location=useLocation()
const navigate=useNavigate()
    const logout=()=>{
        localStorage.clear()
        navigate("/login")
    }

    useEffect(()=>{
if(localStorage.getItem("studenttoken")){
    fetch("http://localhost:3000/api/studentid",{
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            "token":localStorage.getItem("studenttoken")
        },body:JSON.stringify({method:6})
    }).then((ele)=>ele.json()).then((res)=>{
        if(!res.flag1){
            navigate("/profile")
        }
        else{
            navigate("/needassesment")
        }
      
    })
}
    },[])

  
    useEffect(() => {
        let ham = document.getElementById("ham2");
        let nav2 = document.getElementById("newnav");
        let cross = document.getElementById("cross");
        ham.addEventListener("click", () => {

          nav2.classList.add("active-box");
          let x=getComputedStyle(document.getElementById("ham2"))
          ham.style.display = "none";
          cross.addEventListener("click", () => {
            nav2.classList.remove("active-box");
            
              ham.style.display = "flex";
           
          });
        })
        
    }, [])
    
  return (
<>
<div>


    <div className="hamburger-container">
        
        <div className="ham" id="ham2">
            <i className="fa-solid fa-bars menu-btn "></i>
        </div>
    </div>
    <nav>
        <div className="logo-container">
            
            <img className="logo" src="images/logo.png" alt=""/>
        </div>
        <ul>
            <li><i className="fa-solid fa-layer-group"></i><a href="#">Dashboard</a></li>
            <li><i className="fa-solid fa-file-lines"></i><Link to="/needassesment">Need Assesment</Link></li>
            <li><i className="fas fa-info-circle"></i><a href="#">Aarohan Details</a></li>
        </ul>
        <div className="profile-container">
            <img className="profile-img" src="images/profile.png" alt=""/>
            <h4>{localStorage.getItem("studentname")!=="Pending" && localStorage.getItem("studentname")}</h4>
            <p>View details</p>
        </div>
        
        <div className="btn-login-user">
            <button><i className="fa-solid fa-gear"></i> Setting</button>
            {
localStorage.getItem("studenttoken")?
            <button onClick={logout}><i className="fa-solid fa-right-from-bracket"></i> Logout</button>
            :<Link to="/login"><button><i className="fa-solid fa-left-from-bracket"></i> Login</button></Link>
            }
        </div>
    </nav>

    <div className="nav2 flex-box" id="newnav">
        <div className="crossicon" id="cross">
            <span>
                
                <i className="fa-solid fa-circle-arrow-left icon "></i>
            </span>
        </div>
        <img className="logo" src="images/logo.png" alt=""/>
        <ul>
            <li><i className="fa-solid fa-layer-group"></i><a href="#">Dashboard</a></li>
            <li><i className="fa-solid fa-file-lines"></i><Link to="/needassesment">Need Assesment</Link></li>
            <li><i className="fas fa-info-circle"></i><a href="#">Aarohan Details</a></li>
        </ul>
        <div className="profile-container">
            <img className="profile-img" src="images/profile.png" alt=""/>
            <h4>{localStorage.getItem("studentname")}</h4>
            <p>View details</p>
        </div>
        
        <div className="btn-login-user">
            <Link to="/profile"><button><i className="fa-solid fa-gear"></i> Setting</button></Link>
            <button onClick={logout}><i className="fa-solid fa-right-from-bracket"></i> Logout</button>
        </div>
    </div>
    
    
</div>
</>
  )
}

export default Navbar