import React, { useState } from 'react'
import Context from './Mycontext'
const Contextstate = (props) => {
    const [Progress, setProgress] = useState(6)
  return (
    <Context.Provider value={{Progress,setProgress}}>
{props.children}

    </Context.Provider>
  )
}

export default Contextstate