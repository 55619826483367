import React from 'react'

const Details = () => {
  return (
    <section class="sectionDetails">
    <div class="sectionDetailsContainer">
      <div class="socials">
        <button class="linkedin icon">
          <i class="fa-brands fa-linkedin-in"></i>
        </button>
        <button class="instagram icon">
          <i class="fa-brands fa-instagram"></i>
        </button>
        <button class="twitter icon">
          <i class="fa-brands fa-twitter"></i>
        </button>
      </div>

      <div class="detailsContainer">
        <div class="details_desc">
          <div class="details_descc">
            <h1 class="details_desc_heading">Aarohan</h1>
            <p class="details_desc_p">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
        <div class="details_services">
          <div class="service1">
            <button><i class="fa-solid fa-palette"></i></button>
            <h3 class="service1_heading">Lorem Ipsum is simply</h3>
            <p class="service1_desc">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s,
            </p>
          </div>
          <div class="service2">
            <button><i class="fa-solid fa-music"></i></button>
            <h3 class="service2_heading">Lorem Ipsum is simply</h3>
            <p class="service2_desc">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s,
            </p>
          </div>
          <div class="service3">
            <button><i class="fa-solid fa-arrow-trend-up"></i></button>
            <h3 class="service3_heading">Lorem Ipsum is simply</h3>
            <p class="service3_desc">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s,
            </p>
          </div>
          <div class="service4">
            <button><i class="fa-solid fa-fan"></i></button>
            <h3 class="service4_heading">Lorem Ipsum is simply</h3>
            <p class="service4_desc">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s,
            </p>
          </div>
        </div>
        <div class="details_image"></div>
      </div>
    </div>
  </section>
  )
}

export default Details